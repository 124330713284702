// $bgColor: #1c1d22;
$primaryColor: #e8ae55;
// $buttonHoverColor: #da8e19;
// $secondaryColor: #314e98;
$textPrimaryColor: #ffffff;
$textSecondaryColor: #9e9e9e;
// $text-color1: #a4a5a7;
// $placeTextColor: #505053;
// $cardBgColor: #303136;
$orange: #d87e14;
$red: #c2334d;
$blue: #4743db;
$green: #44c658;
$lightgray: #ededed;

$tabdetail: #1e293b;

$purple: #8626C0;
$purple-text:#721ead;
$bgBaseColor: #0b0b45;
$baseColor: #ffffff;
$baseTextColor: #475569;
$titleColor: #301934;
$smTitleColor: #02462d;
$linkColor: #3232a6;
$borderColor: #e7ecf4;
$CardBorderColor: #f5f9ff;
$tabBtnColor: #fcfcfc;
$tabBtnFillColor: #ebf1fa;
$selectRowColor: #f9fbff;
$bgGradient: linear-gradient(180deg, #270067 48.96%, #8626C0 100%);
$bgBtn: linear-gradient(90deg, #3232A6 0%, #8626C0 100%);
$white: #fff;
